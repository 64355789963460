
import { Options, Vue } from 'vue-class-component';
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';

@Options({
  components: {
    Navbar,
    Footer,
  },
})
export default class App extends Vue {}
