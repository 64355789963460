<template>
<Disclosure as="nav" class="bg-gray-800" v-slot="{ open }">
  <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex items-center justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button-->
        <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
          <span class="sr-only">Open main menu</span>
          <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
          <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
        </DisclosureButton>
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="flex-shrink-0 flex items-center">
          <img class="lg:block h-8 w-auto" src="../assets/vizob.svg" alt="Workflow" />
        </div>
        <div class="hidden sm:block sm:ml-6">
          <div class="flex items-center	 space-x-4">
            <router-link v-for="item in navigation" :key="item.name" :to="item.href" v-slot="{ isActive }"
              :class="[isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']">{{ item.name }}</router-link>
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
      </div>
    </div>
  </div>

  <DisclosurePanel class="sm:hidden">
    <div class="px-2 pt-2 pb-3 space-y-1">
      <router-link v-for="item in navigation" :key="item.name" :to="item.href"
      v-slot="{ isActive }"
      :class="[isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</router-link>
    </div>
  </DisclosurePanel>
</Disclosure>
</template>
<script>

import { ref } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

import {
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon, XIcon,
} from '@heroicons/vue/outline';

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'App & Software Development', href: '/app-and-software-development' },
  { name: 'Website Design & Development', href: '/website-design-and-development' },
  { name: 'About Us', href: '/about' },
  { name: 'Contact', href: '/contact' },
];

const vizobServices = [
  {
    name: 'App & Software Development',
    description: 'App & software development that just works.',
    href: '/app-and-software-development',
    icon: ChartBarIcon,
  },
  {
    name: 'Website Design & Development',
    description: 'Websites can often be more of a problem than a solution. We design award-winning websites which just work.',
    href: '/website-design-and-development',
    icon: CursorClickIcon,
  },
];

export default {
  components: {
    MenuIcon,
    XIcon,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },

  setup() {
    const open = ref(false)
    return {
      vizobServices,
      navigation,
      open,
    };
  },
};
</script>
