
import { Options, Vue } from 'vue-class-component';
import Hero from '@/components/Hero.vue';
import FeatureSection from '@/components/FeatureSection.vue';
import WorkHeader from '@/components/WorkHeader.vue';
import Client from '@/components/Client.vue';
import FindOutAboutUS from '@/components/FindOutAboutUS.vue';
import Footer from '@/components/Footer.vue';
import Message from '@/components/Message.vue';
import Testimonials from '@/components/Testimonials.vue';

@Options({
  components: {
    Hero,
    FeatureSection,
    WorkHeader,
    Client,
    Testimonials,
    FindOutAboutUS,
    Message,
    Footer,
  },
})
export default class Home extends Vue {}
