import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/Work.vue'),
  },
  {
    path: '/website-design-and-development',
    name: 'Website Design & Development',
    component: () => import('../views/WebsiteDesign.vue'),
  },
  {
    path: '/app-and-software-development',
    name: 'App & Software Development',
    component: () => import('../views/AppDevelopment.vue'),
  },
  {
    path: '/devops-consulting-and-implementation-services',
    name: 'DevOps Consulting & Implementation Services',
    component: () => import('../views/AppDevelopment.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/cookie-policy',
    name: 'Cookie Policy',
    component: () => import('../views/CookiePolicy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
